<template>
  <v-app>
    <JNavigationDrawer
      v-if="showNavigationDrawer"
      title="JumiaPay Operations"
      :width="width"
      :logo="logo"
      :subtitle="email"
      :menu="menu"
      @sign-out="onSignOut"
    />

    <v-main
      v-if="showContent"
      :class="{
        'grey lighten-5': !showWhiteBackground,
        white: showWhiteBackground
      }"
    >
      <JNotificationBar
        v-model="isSnackbarActive"
        :icon="snackbar.icon"
        :messages="snackbar.messages"
        :text-color="snackbar.textColor"
        :background-color="snackbar.backgroundColor"
        :type="snackbar.type"
        :absolute="
          $route.meta &&
          $route.meta.navigationDrawer &&
          !$route.meta.notificationFixed
        "
      />

      <JLoading v-if="loadingPage" :text="$t('loading_page')" />

      <div v-else-if="hasPermission($route.meta.permission)">
        <router-view></router-view>
      </div>

      <JStatus
        v-else
        :image="lockImage"
        class="mx-auto"
        data-cy="no-permission"
        :title="$t('no_permissions.title')"
        :description="$t('no_permissions.description')"
        :action-label="$t('go_back')"
        @action-clicked="$router.go(-1)"
      />
    </v-main>
    <JBottomNavigation
      v-if="showBottomNavigation"
      :menu="availablePagesOnMenu"
    />

    <v-snackbar v-model="newVersion" right color="primary" :timeout="-1">
      {{ $t('new_version') }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="refreshApp">
          {{ $t('refresh') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import JLoading from '@JumiaPayAIG/j-loading'
import JStatus from '@JumiaPayAIG/j-status'
import logo from '@/assets/logo.png'
import lockImage from '@/assets/lock.png'
export default {
  name: 'App',
  components: {
    JStatus,
    JLoading,
    JNotificationBar: () =>
      import(
        /* webpackChunkName: "JNotificationBar" */ '@JumiaPayAIG/j-notification-bar'
      ),
    JNavigationDrawer: () =>
      import(
        /* webpackChunkName: "JNavigationDrawer" */ '@JumiaPayAIG/j-navigation-drawer'
      ),
    JBottomNavigation: () =>
      import(
        /* webpackChunkName: "JBottomNavigation" */ '@JumiaPayAIG/j-bottom-navigation'
      )
  },
  data() {
    return {
      logo,
      lockImage,
      newVersion: false,
      isSnackbarActive: false
    }
  },
  computed: {
    ...mapState('app', {
      loadingPage: (state) => state.loadingPage,
      email: (state) => state.email,
      menu: (state) => state.menu,
      snackbar: (state) => state.snackbar
    }),
    ...mapGetters({
      availablePagesOnMenu: 'app/availablePagesOnMenu',
      hasPermission: 'app/hasPermission'
    }),
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    width() {
      if (this.isDesktop) {
        return this.$vuetify.breakpoint.lgAndUp ? '325' : '242'
      }
      return '100%'
    },
    isMenuHash() {
      return this.$route.hash === '#menu'
    },
    showWhiteBackground() {
      return (
        !this.$route.meta.navigationDrawer ||
        (this.isMenuHash && !this.isDesktop)
      )
    },
    showNavigationDrawer() {
      return (
        (this.$route.meta.navigationDrawer && this.isDesktop) ||
        (this.isMenuHash && !this.isDesktop)
      )
    },
    showContent() {
      return this.isDesktop || (!this.isMenuHash && !this.isDesktop)
    },
    showBottomNavigation() {
      return (
        !this.isDesktop &&
        this.$route.meta.navigationDrawer &&
        this.availablePagesOnMenu.length > 0
      )
    }
  },
  watch: {
    snackbar: {
      deep: true,
      handler(value) {
        if (value.messages && value.messages.length > 0) {
          this.isSnackbarActive = true
        }
      }
    },
    isSnackbarActive(value) {
      if (!value) {
        this.cleanSnackbar()
      }
    }
  },
  created() {
    window.addEventListener('new-service-worker', (e) => {
      this.newVersion = true
    })
  },
  methods: {
    ...mapActions({
      login: 'app/login',
      logout: 'app/logout',
      showErrorNotification: 'app/showErrorNotification',
      cleanSnackbar: 'app/cleanSnackbar'
    }),
    onSignOut() {
      this.logout()
      this.$router.push({ name: 'homepage' })
    },
    refreshApp() {
      location.reload()
    }
  }
}
</script>
