import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import errors from '@/locales/errors.en.json'
import { setAxiosAcceptLanguage } from '@/api/axios-configurations'
import { getEnv } from '@JumiaPayAIG/js-utils/env'

Vue.use(VueI18n)

const locale = getEnv('VUE_APP_I18N_LOCALE') || 'en'

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: {
      ...en,
      errors
    }
  }
})

function setI18nLanguage(lang) {
  i18n.locale = lang
  setAxiosAcceptLanguage(lang)
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

async function loadModuleLanguage({ module }) {
  if (!i18n.messages[locale][module]) {
    const msgs = await import(
      /* webpackChunkName: "lang-[request]" */ `@/locales/${module}.${locale}.json`
    )
    i18n.mergeLocaleMessage(locale, {
      [module]: msgs.default
    })
    return setI18nLanguage(locale)
  }
}

export async function loadLanguage({ module }) {
  if (!i18n.messages[locale]) {
    const msgs = await import(
      /* webpackChunkName: "lang-[request]" */ `@/locales/${locale}.json`
    )

    i18n.setLocaleMessage(locale, msgs.default)
    await loadModuleLanguage({ module: 'errors' })
    if (module) {
      return loadModuleLanguage({ module })
    }
  } else if (module) {
    return loadModuleLanguage({ module })
  }
}
