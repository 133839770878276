import { mdiDotsHorizontal } from '@mdi/js'

function availablePagesOnMenu(state) {
  if (state.menu) {
    let menu = state.menu.filter(function filterSubHeader(el) {
      return !el.isGroup
    })

    menu = menu.slice(0, 2)
    menu.push({
      text: 'More',
      icon: mdiDotsHorizontal,
      to: { hash: 'menu' }
    })

    return menu
  }
  return null
}

function hasPermission(state) {
  return function (permission) {
    if (!permission) return true
    return state.permissions.some(function findPermission(el) {
      return el === permission
    })
  }
}

export default {
  availablePagesOnMenu,

  hasPermission
}
