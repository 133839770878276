import {
  mdiBankOutline,
  mdiBankTransfer,
  mdiFormatListBulleted,
  mdiTextBoxCheck,
  mdiArrowDecisionOutline,
  mdiPlaylistEdit,
  mdiShopping,
  mdiAlertOctagon,
  mdiFlag,
  mdiAccountCog,
  mdiCreditCard
} from '@mdi/js'
import { i18n } from '@/i18n'
import { purchasesList, disputesList } from '../router/purchases'
import { requestManagementList } from '../router/requests-management'
import { operatingAccountsList } from '../router/operating-accounts'
import { listManagementList } from '../router/lists-management'
import { jxSanctionsList } from '../router/jx-sanctions'
import {
  manualWithdrawalsList,
  manualWithdrawalsBulkProcessing
} from '../router/manual-withdrawals'
import { decisionsListRoute } from '../router/decisions'
import { businessesList } from '../router/businesses'
import { settlementsByBankList } from '../router/settlement-by-bank'
import { binList } from '../router/bin'

export default [
  {
    name: i18n.t('menu.services'),
    subModules: [
      {
        text: i18n.t('menu.purchases'),
        icon: mdiShopping,
        permission: purchasesList.meta.permission,
        to: { name: purchasesList.name },
        code: 'purchases'
      },
      {
        text: i18n.t('menu.disputes'),
        icon: mdiFlag,
        permission: disputesList.meta.permission,
        to: { name: disputesList.name },
        code: 'disputes'
      }
    ]
  },
  {
    name: i18n.t('menu.manual_withdrawals'),
    subModules: [
      {
        text: i18n.t('menu.manual_withdrawals_list'),
        icon: mdiFormatListBulleted,
        permission: manualWithdrawalsList.meta.permission,
        to: { name: manualWithdrawalsList.name },
        code: 'manual-withdrawals'
      },
      {
        text: i18n.t('menu.manual_withdrawals_bulk_processing'),
        icon: mdiTextBoxCheck,
        permission: manualWithdrawalsList.meta.permission,
        to: { name: manualWithdrawalsBulkProcessing.name },
        code: 'manual-withdrawals-bulk-processing'
      }
    ]
  },
  {
    name: i18n.t('menu.risk_engine'),
    subModules: [
      {
        text: i18n.t('menu.requests'),
        to: { name: requestManagementList.name },
        permission: requestManagementList.meta.permission,
        code: 'requests'
      },
      {
        text: i18n.t('menu.decisions'),
        icon: mdiArrowDecisionOutline,
        to: { name: decisionsListRoute.name },
        permission: decisionsListRoute.meta.permission,
        code: 'decisions'
      },
      {
        text: i18n.t('menu.lists_management'),
        icon: mdiPlaylistEdit,
        to: { name: listManagementList.name },
        permission: listManagementList.meta.permission,
        code: 'lists-management'
      }
    ]
  },
  {
    name: i18n.t('menu.compliance'),
    subModules: [
      {
        text: i18n.t('menu.sanctions-list'),
        icon: mdiAlertOctagon,
        to: { name: jxSanctionsList.name },
        permission: jxSanctionsList.meta.permission,
        code: 'sanctions-list'
      }
    ]
  },
  {
    name: i18n.t('menu.administration'),
    subModules: [
      {
        text: i18n.t('menu.operating_accounts'),
        icon: mdiBankOutline,
        permission: operatingAccountsList.meta.permission,
        to: { name: operatingAccountsList.name },
        code: 'operating-accounts'
      },
      {
        text: i18n.t('menu.settlements_by_bank'),
        icon: mdiBankTransfer,
        permission: settlementsByBankList.meta.permission,
        to: { name: settlementsByBankList.name },
        code: 'settlement-by-bank'
      },
      {
        text: i18n.t('menu.bin'),
        icon: mdiCreditCard,
        permission: binList.meta.permission,
        to: { name: binList.name },
        code: 'bin'
      }
    ]
  },
  {
    name: i18n.t('menu.users'),
    subModules: [
      {
        text: i18n.t('menu.businesses'),
        icon: mdiAccountCog,
        permission: businessesList.meta.permission,
        to: { name: businessesList.name },
        code: 'businesses'
      }
    ]
  }
]
