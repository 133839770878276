var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.showNavigationDrawer)?_c('JNavigationDrawer',{attrs:{"title":"JumiaPay Operations","width":_vm.width,"logo":_vm.logo,"subtitle":_vm.email,"menu":_vm.menu},on:{"sign-out":_vm.onSignOut}}):_vm._e(),(_vm.showContent)?_c('v-main',{class:{
      'grey lighten-5': !_vm.showWhiteBackground,
      white: _vm.showWhiteBackground
    }},[_c('JNotificationBar',{attrs:{"icon":_vm.snackbar.icon,"messages":_vm.snackbar.messages,"text-color":_vm.snackbar.textColor,"background-color":_vm.snackbar.backgroundColor,"type":_vm.snackbar.type,"absolute":_vm.$route.meta &&
        _vm.$route.meta.navigationDrawer &&
        !_vm.$route.meta.notificationFixed},model:{value:(_vm.isSnackbarActive),callback:function ($$v) {_vm.isSnackbarActive=$$v},expression:"isSnackbarActive"}}),(_vm.loadingPage)?_c('JLoading',{attrs:{"text":_vm.$t('loading_page')}}):(_vm.hasPermission(_vm.$route.meta.permission))?_c('div',[_c('router-view')],1):_c('JStatus',{staticClass:"mx-auto",attrs:{"image":_vm.lockImage,"data-cy":"no-permission","title":_vm.$t('no_permissions.title'),"description":_vm.$t('no_permissions.description'),"action-label":_vm.$t('go_back')},on:{"action-clicked":function($event){return _vm.$router.go(-1)}}})],1):_vm._e(),(_vm.showBottomNavigation)?_c('JBottomNavigation',{attrs:{"menu":_vm.availablePagesOnMenu}}):_vm._e(),_c('v-snackbar',{attrs:{"right":"","color":"primary","timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":_vm.refreshApp}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('refresh'))+" ")])]}}]),model:{value:(_vm.newVersion),callback:function ($$v) {_vm.newVersion=$$v},expression:"newVersion"}},[_vm._v(" "+_vm._s(_vm.$t('new_version'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }