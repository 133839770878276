import { loadView, getMappedPermission } from './utils'
import MODULES from '../constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const businessesRoot = {
  path: '/businesses',
  component: loadView('ViewBusinesses', MODULES.BUSINESSES)
}

export const businessesList = {
  path: '',
  name: 'businesses',
  component: loadView('ViewBusinessesList', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: true,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ,
      MODULES.BUSINESS_PROFILE
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('businesses.title.list')
      }
    }
  }
}

export const businessDetails = {
  path: ':businessId',
  name: 'business-details',
  component: loadView('ViewBusinessDetails', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: true,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ,
      MODULES.BUSINESS_PROFILE
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('businesses.title.details'),
        parent: businessesList.name
      }
    }
  }
}

export const businessShopsList = {
  path: ':businessId/shops',
  name: 'shop-configs-list',
  component: loadView('ViewBusinessShopsList', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: true,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ,
      MODULES.BUSINESS_PROFILE
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('businesses.shops.title.list')
      }
    }
  }
}

export const businessShopDetails = {
  path: ':businessId/shops/:shopId',
  name: 'shop-configs-details',
  component: loadView('ViewBusinessShopDetails', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: true,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ,
      MODULES.BUSINESS_PROFILE
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('businesses.shops.title.details'),
        parent: businessDetails.name
      }
    }
  }
}

export const businessProfileEdit = {
  path: ':businessId/edit',
  name: 'business-profile-edit',
  component: loadView('ViewBusinessProfileEdit', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(
      PERMISSION_TYPES.EDIT,
      MODULES.BUSINESS_PROFILE
    )
  }
}

export const businessShopEdit = {
  path: ':businessId/shops/:shopId/edit',
  name: 'shop-configs-edit',
  component: loadView('ViewBusinessShopEdit', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(
      PERMISSION_TYPES.EDIT,
      MODULES.BUSINESS_PROFILE
    )
  }
}

export const discountsList = {
  path: ':businessId/discounts',
  name: 'discounts',
  component: loadView('ViewDiscountsList', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: true,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.READ, MODULES.DISCOUNTS),
    breadcrumb: () => {
      return {
        text: i18n.t('businesses.discounts.title.list'),
        parent: businessDetails.name
      }
    }
  }
}

export const discountsCreate = {
  path: ':businessId/discounts/create',
  name: 'discounts-create',
  component: loadView('ViewDiscountCreate', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.CREATE, MODULES.DISCOUNTS)
  }
}

export const discountEdit = {
  path: ':businessId/discounts/:id/edit',
  name: 'discount-edit',
  component: loadView('ViewDiscountEdit', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.CREATE, MODULES.DISCOUNTS),
    breadcrumb: () => {
      return {
        text: i18n.t('businesses.discounts.title.edit'),
        parent: discountDetails.name
      }
    }
  }
}

export const discountDetails = {
  path: ':businessId/discounts/:id',
  name: 'discount-details',
  component: loadView('ViewDiscountDetails', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: true,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.READ, MODULES.DISCOUNTS),
    breadcrumb: () => {
      return {
        text: i18n.t('businesses.discounts.title.details'),
        parent: discountsList.name
      }
    }
  }
}

export const returnValueEdit = {
  path: ':businessId/discounts/:id/return-value',
  name: 'return-value-edit',
  component: loadView('ViewReturnValueEdit', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.CREATE, MODULES.DISCOUNTS)
  }
}

export const returnValueCreate = {
  path: ':businessId/discounts/:id/return-value/create',
  name: 'return-value-create',
  component: loadView('ViewReturnValueCreate', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.CREATE, MODULES.DISCOUNTS)
  }
}

export const conditionCreate = {
  path: ':businessId/discounts/:id/conditions/create',
  name: 'condition-create',
  component: loadView('ViewConditionCreate', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.CREATE, MODULES.DISCOUNTS)
  }
}

export const conditionsView = {
  path: ':businessId/discounts/:id/conditions/:conditionId',
  name: 'condition-view',
  component: loadView('ViewConditionView', MODULES.BUSINESSES),
  meta: {
    navigationDrawer: false,
    module: MODULES.BUSINESSES,
    permission: getMappedPermission(PERMISSION_TYPES.READ, MODULES.DISCOUNTS)
  }
}

export default [
  {
    ...businessesRoot,
    children: [
      businessesList,
      businessDetails,
      businessShopsList,
      businessShopDetails,
      businessShopEdit,
      businessProfileEdit,
      discountsList,
      discountsCreate,
      discountDetails,
      discountEdit,
      returnValueEdit,
      returnValueCreate,
      conditionCreate,
      conditionsView
    ]
  }
]
