import { localStorageKeys } from '@/constants/index'
import { toadInstance } from './axios-configurations'
import { getEnv } from '@JumiaPayAIG/js-utils/env'

export const endpoints = {
  login: 'login',
  logout: 'logout',
  refreshToken: 'refresh-token',
  permissions: 'permissions'
}

async function apiLogin({ authCode }) {
  const { data } = await toadInstance.post(endpoints.login, {
    auth_code: authCode,
    redirect_uri: window.location.origin,
    context:
      getEnv('VUE_APP_NEW_LOGIN_ENABLED') === 'true' ? 'operations' : 'default',
    user_type: 'internal'
  })

  const {
    email,
    auth_token: authToken,
    refresh_token: refreshToken,
    permissions
  } = data

  return {
    email,
    authToken,
    refreshToken,
    permissions
  }
}

async function apiGetPermissions() {
  const { data } = await toadInstance.get(endpoints.permissions)
  return data
}

function apiLogout() {
  toadInstance.post(endpoints.logout)
}

async function apiRefreshToken() {
  const { data } = await toadInstance.post(endpoints.refreshToken, {
    auth_token: localStorage.getItem(localStorageKeys.authToken),
    refresh_token: localStorage.getItem(localStorageKeys.refreshToken)
  })

  const { auth_token: authToken, refresh_token: refreshToken } = data

  return {
    authToken,
    refreshToken
  }
}

export { apiLogin, apiGetPermissions, apiLogout, apiRefreshToken }
