import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App'
import router from './router/index.js'
import store from './store'
import './register-service-worker'
import { i18n } from './i18n'
import '@/fonts.css'
import notificationPlugin from './plugins/notification'
import { getEnv } from '@JumiaPayAIG/js-utils/env'
import VueClipboard from 'vue-clipboard2'
import VueGtag from 'vue-gtag'
import { VueMaskDirective } from 'v-mask'

Vue.config.productionTip = false

Vue.directive('mask', VueMaskDirective)

Vue.use(VueClipboard)
Vue.use(notificationPlugin)

Vue.use(
  VueGtag,
  {
    config: { id: getEnv('VUE_APP_GOOGLE_ANALYTICS_ID') }
  },
  router
)

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start()
}

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
