import { loadView, getMappedPermission } from './utils'
import MODULES from '@/constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const listManagementRoot = {
  path: '/lists-management',
  component: loadView('ViewListsManagemnt', MODULES.LISTS_MANAGEMENT)
}

export const listManagementList = {
  path: '',
  name: 'lists-management',
  component: loadView('ViewListsManagementList', MODULES.LISTS_MANAGEMENT),
  meta: {
    navigationDrawer: true,
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.LISTS_MANAGEMENT
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('lists-management.breadcrumbs.list')
      }
    }
  }
}

export const listManagementRecords = {
  path: ':id',
  name: 'lists-management-records',
  component: loadView('ViewListManagementRecords', MODULES.LISTS_MANAGEMENT),
  meta: {
    navigationDrawer: true,
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.LISTS_MANAGEMENT
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('lists-management.breadcrumbs.listDetails'),
        parent: listManagementList.name
      }
    }
  }
}

export const listManagementRecordsFullList = {
  path: ':id/records',
  name: 'lists-management-records-full',
  component: loadView('ViewListsRecordsFull', MODULES.LISTS_MANAGEMENT),
  meta: {
    navigationDrawer: true,
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.LISTS_MANAGEMENT
    ),
    breadcrumb: ({ sid }) => {
      return {
        text: sid,
        parent: listManagementRecords.name
      }
    }
  }
}

export const listManagementActionLogs = {
  path: ':id/action-logs',
  name: 'lists-management-action-logs',
  component: loadView('ViewActionLogsList', MODULES.LISTS_MANAGEMENT),
  meta: {
    navigationDrawer: true,
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.LISTS_MANAGEMENT
    ),
    breadcrumb: ({ sid }) => {
      return {
        text: sid,
        parent: listManagementRecords.name
      }
    }
  }
}

export const createList = {
  path: 'new',
  name: 'create-list',
  component: loadView('ViewListCreate', MODULES.LISTS_MANAGEMENT),
  meta: {
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.CREATE,
      MODULES.LISTS_MANAGEMENT
    )
  }
}

export const editList = {
  path: ':id/edit',
  name: 'edit-list',
  component: loadView('ViewListEdit', MODULES.LISTS_MANAGEMENT),
  meta: {
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.CREATE,
      MODULES.LISTS_MANAGEMENT
    )
  }
}
export const createListRecords = {
  path: ':id/create',
  name: 'create-list-records',
  component: loadView('ViewListRecordsCreate', MODULES.LISTS_MANAGEMENT),
  meta: {
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.CREATE,
      MODULES.LISTS_MANAGEMENT
    )
  }
}

export const bulkUploadRecords = {
  path: ':id/upload',
  name: 'bulk-upload-records',
  component: loadView('ViewListRecordsBulkUpload', MODULES.LISTS_MANAGEMENT),
  meta: {
    module: MODULES.LISTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.CREATE,
      MODULES.LISTS_MANAGEMENT
    )
  }
}

export default [
  {
    ...listManagementRoot,
    children: [
      listManagementList,
      createList,
      editList,
      listManagementRecords,
      listManagementRecordsFullList,
      listManagementActionLogs,
      bulkUploadRecords,
      createListRecords
    ]
  }
]
