function loadView(view, module) {
  if (module) {
    return () =>
      import(
        /* webpackChunkName: "[request]" */ `@/modules/${module}/${view}.vue`
      )
  }
  return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`)
}

function getMappedPermission(permissionType, module) {
  return `${permissionType}_${module}`
}
export { loadView, getMappedPermission }
