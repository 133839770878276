import { loadView, getMappedPermission } from './utils'
import MODULES from '../constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const purchasesRoot = {
  path: '/purchases',
  component: loadView('ViewPurchases', MODULES.PURCHASES)
}

export const purchasesList = {
  path: '',
  name: 'purchases',
  component: loadView('ViewPurchasesList', MODULES.PURCHASES),
  meta: {
    navigationDrawer: true,
    module: MODULES.PURCHASES,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.PURCHASES
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.title.list')
      }
    }
  }
}

export const purchasesDetails = {
  path: ':purchaseId',
  name: 'purchases-details',
  component: loadView('ViewPurchasesDetails', MODULES.PURCHASES),
  meta: {
    navigationDrawer: true,
    module: MODULES.PURCHASES,
    permission: getMappedPermission(PERMISSION_TYPES.READ, MODULES.PURCHASES),
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.title.details'),
        parent: purchasesList.name
      }
    }
  }
}

export const purchasesDisputesList = {
  path: ':purchaseId/disputes',
  name: 'purchases-disputes-list',
  component: loadView('ViewPurchasesDisputesList', MODULES.PURCHASES),
  meta: {
    navigationDrawer: true,
    module: MODULES.PURCHASES,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.PURCHASES_DISPUTES
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.disputes.title.list'),
        parent: purchasesDetails.name
      }
    }
  }
}

export const purchasesDisputesCreate = {
  path: ':purchaseId/disputes/new',
  name: 'purchases-disputes-add',
  component: loadView('ViewPurchasesDisputesAdd', MODULES.PURCHASES),
  meta: {
    module: MODULES.PURCHASES,
    permission: getMappedPermission(
      PERMISSION_TYPES.CREATE,
      MODULES.PURCHASES_DISPUTES
    )
  }
}

export const purchasesDisputesDetails = {
  path: ':purchaseId/disputes/:disputeId',
  name: 'purchases-disputes-details',
  component: loadView('ViewPurchasesDisputesDetails', MODULES.PURCHASES),
  meta: {
    navigationDrawer: true,
    module: MODULES.PURCHASES,
    permission: getMappedPermission(PERMISSION_TYPES.READ, MODULES.DISPUTES),
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.disputes.title.details'),
        parent: purchasesDetails.name
      }
    }
  }
}

export const purchasesDisputesReopen = {
  path: ':purchaseId/disputes/:disputeId/reopen',
  name: 'purchases-disputes-reopen',
  component: loadView('ViewPurchasesDisputesReopen', MODULES.PURCHASES),
  meta: {
    module: MODULES.PURCHASES,
    permission: getMappedPermission(PERMISSION_TYPES.REOPEN, MODULES.DISPUTES)
  }
}

export const disputesRoot = {
  path: '/disputes',
  component: loadView('ViewDisputes', MODULES.PURCHASES)
}

export const disputesList = {
  path: '',
  name: 'disputes-list',
  component: loadView('ViewDisputesList', MODULES.PURCHASES),
  meta: {
    navigationDrawer: true,
    module: MODULES.PURCHASES,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.DISPUTES
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.disputes.title.list')
      }
    }
  }
}

export const disputesDetails = {
  path: ':disputeId',
  name: 'disputes-details',
  component: loadView('ViewDisputesDetails', MODULES.PURCHASES),
  meta: {
    navigationDrawer: true,
    module: MODULES.PURCHASES,
    permission: getMappedPermission(PERMISSION_TYPES.READ, MODULES.DISPUTES),
    breadcrumb: () => {
      return {
        text: i18n.t('purchases.disputes.title.details'),
        parent: disputesList.name
      }
    }
  }
}

export const disputesDocumentDownload = {
  path: 'documents/:documentId/download',
  name: 'disputes-document-download',
  component: loadView('ViewDisputesDocumentDownload', MODULES.PURCHASES),
  meta: {
    module: MODULES.PURCHASES,
    permission: getMappedPermission(
      PERMISSION_TYPES.DOWNLOAD_DOCUMENTS,
      MODULES.DISPUTES
    )
  }
}

export default [
  {
    ...purchasesRoot,
    children: [
      purchasesList,
      purchasesDetails,
      purchasesDisputesList,
      purchasesDisputesCreate,
      purchasesDisputesDetails,
      purchasesDisputesReopen
    ]
  },
  {
    ...disputesRoot,
    children: [disputesList, disputesDetails, disputesDocumentDownload]
  }
]
