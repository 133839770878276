export default {
  REQUESTS_MANAGEMENT: 'requests-management',
  OPERATING_ACCOUNTS: 'operating-accounts',
  MANUAL_WITHDRAWALS: 'manual-withdrawals',
  DECISIONS: 'decisions',
  LISTS_MANAGEMENT: 'lists-management',
  PURCHASES: 'purchases',
  JX_SANCTIONS: 'jx-sanctions',
  PURCHASES_DISPUTES: 'purchases-disputes',
  DISPUTES: 'disputes',
  BUSINESSES: 'businesses',
  BUSINESS_PROFILE: 'business_profile',
  BUSINESS_FEATURES: 'business_features',
  SETTLEMENTS_BY_BANK: 'settlement-by-bank',
  BIN: 'bin',
  DISCOUNTS: 'discounts'
}
