import { loadView, getMappedPermission } from './utils'
import MODULES from '@/constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const jxSanctionsRoot = {
  path: '/jx-sanctions',
  component: loadView('ViewJxSanctions', MODULES.JX_SANCTIONS)
}

export const jxSanctionsList = {
  path: '',
  name: 'jx-sanctions',
  component: loadView('ViewJxSanctionsList', MODULES.JX_SANCTIONS),
  meta: {
    navigationDrawer: true,
    module: MODULES.JX_SANCTIONS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.JX_SANCTIONS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('jx-sanctions.breadcrumbs.sanctionsList')
      }
    }
  }
}

export const jxSanctionsHistoryDetails = {
  path: ':id',
  name: 'jx-sanctions-history-details',
  component: loadView('ViewJxSanctionsHistoryDetails', MODULES.JX_SANCTIONS),
  meta: {
    navigationDrawer: true,
    module: MODULES.JX_SANCTIONS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.JX_SANCTIONS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('jx-sanctions.breadcrumbs.sanctionsListDetails'),
        parent: jxSanctionsList.name
      }
    }
  }
}

export const jxSanctionsExport = {
  path: 'export',
  name: 'jx-sanctions-export',
  component: loadView('ViewJxSanctionsListExport', MODULES.JX_SANCTIONS),
  meta: {
    navigationDrawer: false,
    module: MODULES.JX_SANCTIONS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.JX_SANCTIONS
    )
  }
}

export const jxSanctionsUpload = {
  path: 'upload',
  name: 'jx-sanctions-upload',
  component: loadView('ViewJxSanctionsUpload', MODULES.JX_SANCTIONS),
  meta: {
    navigationDrawer: false,
    module: MODULES.JX_SANCTIONS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.JX_SANCTIONS
    )
  }
}

export default [
  {
    ...jxSanctionsRoot,
    children: [
      jxSanctionsList,
      jxSanctionsHistoryDetails,
      jxSanctionsExport,
      jxSanctionsUpload
    ]
  }
]
