import { localStorageKeys } from '@/constants/index'
import { setAxiosAuthorization } from '@/api/axios-configurations'
import menu from '@/constants/menu'
import {
  apiLogin,
  apiGetPermissions,
  apiLogout,
  apiRefreshToken
} from '../../api/authentication'
import { getEnv } from '@JumiaPayAIG/js-utils/env'

function loadingPageStart({ commit }) {
  commit('LOADING_PAGE_START')
}

function loadingPageEnd({ commit }) {
  commit('LOADING_PAGE_END')
}

function permissionsToExclude(permissions) {
  if (getEnv('VUE_APP_MODULES_TO_EXCLUDE')) {
    const excludeModules = getEnv('VUE_APP_MODULES_TO_EXCLUDE').split(',')
    const trimmedExcludeModules = excludeModules.map((phrase) => phrase.trim())

    permissions = permissions.filter((permission) => {
      // Check if any of the phrases in trimmedExcludeModules are a substring of the permission
      return !trimmedExcludeModules.some((phrase) =>
        permission.includes(phrase)
      )
    })
  }

  return permissions
}

async function newLogin() {
  let returnUrl = window.location.origin + window.location.pathname

  if (window.location.search) {
    returnUrl += window.location.search
  }

  window.location.href = `${getEnv(
    'VUE_APP_ACL_URL'
  )}login?redirect=${returnUrl}&application=jumiapay_admin&aclFlow=v2`
}

async function login({ commit }, { authCode }) {
  const { email, authToken, refreshToken, permissions } = await apiLogin({
    authCode
  })

  localStorage.setItem(localStorageKeys.email, email)

  setAuthTokens({ authToken, refreshToken })
  setAxiosAuthorization(authToken)

  const finalPermissions = permissionsToExclude(permissions)

  commit('EMAIL_UPDATED', localStorage.getItem('email'))
  commit('PERMISSIONS_UPDATED', finalPermissions)
  commit('MENU_ITEMS_UPDATED', getMenuItems(finalPermissions))
}

async function getPermissions({ commit }) {
  const permissions = await apiGetPermissions()
  const finalPermissions = permissionsToExclude(permissions)

  commit('PERMISSIONS_UPDATED', finalPermissions)
  commit('MENU_ITEMS_UPDATED', getMenuItems(finalPermissions))
}

function logout({ commit }) {
  apiLogout()
  localStorage.clear()
  commit('EMAIL_UPDATED', null)
}

async function refreshToken() {
  const { authToken, refreshToken } = await apiRefreshToken()

  setAuthTokens({ authToken, refreshToken })
  setAxiosAuthorization(authToken)

  return { authToken, refreshToken }
}

function setAuthTokens({ authToken, refreshToken }) {
  localStorage.setItem(localStorageKeys.authToken, authToken)
  localStorage.setItem(localStorageKeys.refreshToken, refreshToken)
}

// Notifications

function showSuccessNotification({ commit }, { action, module, message }) {
  commit('SNACKBAR_UPDATED', { module, action, type: 'success', message })
}

function showErrorNotification({ commit }, { message }) {
  commit('SNACKBAR_UPDATED', { type: 'error', message })
}

function cleanSnackbar({ commit }) {
  commit('SNACKBAR_CLEANED')
}

function getMenuItems(permissions) {
  let availableMenu = []

  menu.forEach((module) => {
    const subModules = module.subModules.reduce(
      function selectSubModulesWithPermission(
        availableSubModules,
        subModuleBeingEvaluated
      ) {
        let hasModulePermission = false

        // Check submodules
        if (subModuleBeingEvaluated.modules) {
          const subSubModules = subModuleBeingEvaluated.modules.reduce(
            function selectSubSubModulesWithPermission(
              availableSubSubModules,
              subSubModuleBeingEvaluated
            ) {
              // Check if the user has the permisson for the current subsubmodule
              const hasModulePermission = permissions.some(
                (permission) =>
                  permission === subSubModuleBeingEvaluated.permission
              )

              // update the avalibale subsubmodules menu
              if (hasModulePermission) {
                return [...availableSubSubModules, subSubModuleBeingEvaluated]
              } else {
                return availableSubSubModules
              }
            },
            []
          )

          if (subSubModules.length > 0) {
            const selected = {
              ...subModuleBeingEvaluated,
              modules: subSubModules
            }
            return [...availableSubModules, selected]
          } else {
            return availableSubModules
          }
        } else {
          // Check if the user has the permisson for the current submodule
          hasModulePermission = permissions.some(
            (permission) => permission === subModuleBeingEvaluated.permission
          )
        }

        // update the avalibale submodules menu
        if (hasModulePermission) {
          return [...availableSubModules, subModuleBeingEvaluated]
        } else {
          return availableSubModules
        }
      },
      []
    )

    // Update the available menu with the available module and sub modules
    if (subModules.length > 0) {
      availableMenu = [
        ...availableMenu,
        {
          text: module.name,
          isGroup: true
        },
        ...subModules
      ]
    }
  })
  return availableMenu
}

export default {
  loadingPageStart,
  loadingPageEnd,

  login,
  logout,
  refreshToken,

  getPermissions,

  cleanSnackbar,
  showSuccessNotification,
  showErrorNotification,

  newLogin
}
