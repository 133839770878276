import axios from 'axios'
import { errorHandler } from './axios-interceptors'
import { localStorageKeys } from '@/constants/index'
import { getEnv } from '@JumiaPayAIG/js-utils/env'
import qs from 'qs'

export const headers = {
  authorization: 'Authorization',
  acceptLanguage: 'Accept-Language',
  application: 'Application',
  apikey: 'apikey',
  authToken: 'X-Auth-Token',
  countryCode: 'country-code'
}

// Common configurations
axios.defaults.withCredentials = true
axios.defaults.headers.common[headers.acceptLanguage] = getEnv(
  'VUE_APP_I18N_LOCALE'
)
axios.defaults.headers.common[headers.application] = 'jumiapay_admin'

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'repeat' })
}

// Axios instances

// Authentication/Authorization API
const toadInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'test' ? '' : getEnv('VUE_APP_TOAD_API_ENDPOINT')
})

const bowserInstance = axios.create({
  baseURL: getEnv('VUE_APP_BOWSER_API_ENDPOINT')
})

const superMarioInstance = axios.create({
  baseURL: getEnv('VUE_APP_SUPERMARIO_API_ENDPOINT')
})

const binInstance = axios.create({
  baseURL: getEnv('VUE_APP_BIN_API_ENDPOINT')
})

const manualGatewayInstance = axios.create({
  baseURL: getEnv('VUE_APP_MANUALGATEWAY_API_ENDPOINT')
})

const riskEngineGatewayInstance = axios.create({
  baseURL: getEnv('VUE_APP_RISKENGINE_API_ENDPOINT')
})

const jxSanctionsGatewayInstance = axios.create({
  baseURL: getEnv('VUE_APP_JXSANCTIONS_API_ENDPOINT')
})

const warioInstance = axios.create({
  baseURL: getEnv('VUE_APP_WARIO_API_ENDPOINT'),
  headers: {
    [[headers.apikey]]: getEnv('VUE_APP_WARIO_API_KEY')
  }
})

const billingInstance = axios.create({
  baseURL: getEnv('VUE_APP_BILLING_API_ENDPOINT'),
  headers: {
    [[headers.countryCode]]: getEnv('VUE_APP_COUNTRY_CODE').toUpperCase()
  }
})

const ruleEngineInstance = axios.create({
  baseURL: getEnv('VUE_APP_RULEENGINE_API_ENDPOINT')
})

const storedAuthToken = localStorage.getItem(localStorageKeys.authToken)
if (storedAuthToken) {
  setAxiosAuthorization(storedAuthToken)
}

// Interceptors
function bypassHandler(response) {
  return response
}
axios.interceptors.response.use(bypassHandler, errorHandler)
toadInstance.interceptors.response.use(bypassHandler, errorHandler)
bowserInstance.interceptors.response.use(bypassHandler, errorHandler)
superMarioInstance.interceptors.response.use(bypassHandler, errorHandler)
binInstance.interceptors.response.use(bypassHandler, errorHandler)
manualGatewayInstance.interceptors.response.use(bypassHandler, errorHandler)
riskEngineGatewayInstance.interceptors.response.use(bypassHandler, errorHandler)
warioInstance.interceptors.response.use(bypassHandler, errorHandler)
billingInstance.interceptors.response.use(bypassHandler, errorHandler)
jxSanctionsGatewayInstance.interceptors.response.use(
  bypassHandler,
  errorHandler
)
ruleEngineInstance.interceptors.response.use(bypassHandler, errorHandler)

// Auxiliary methods
function setAxiosAuthorization(token) {
  toadInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`

  bowserInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`

  superMarioInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`

  binInstance.defaults.headers.common[headers.authorization] = `Bearer ${token}`

  manualGatewayInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`

  riskEngineGatewayInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`

  jxSanctionsGatewayInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`

  warioInstance.defaults.headers.common[headers.authToken] = token

  billingInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`

  ruleEngineInstance.defaults.headers.common[
    headers.authorization
  ] = `Bearer ${token}`
}

function setAxiosAcceptLanguage(lang) {
  toadInstance.defaults.headers.common[headers.acceptLanguage] = lang
  bowserInstance.defaults.headers.common[headers.acceptLanguage] = lang
  superMarioInstance.defaults.headers.common[headers.acceptLanguage] = lang
  binInstance.defaults.headers.common[headers.acceptLanguage] = lang
  manualGatewayInstance.defaults.headers.common[headers.acceptLanguage] = lang
  riskEngineGatewayInstance.defaults.headers.common[headers.acceptLanguage] =
    lang

  jxSanctionsGatewayInstance.defaults.headers.common[headers.acceptLanguage] =
    lang
  warioInstance.defaults.headers.common[headers.acceptLanguage] = lang
  billingInstance.defaults.headers.common[headers.acceptLanguage] = lang
  ruleEngineInstance.defaults.headers.common[headers.acceptLanguage] = lang
}

export {
  toadInstance,
  superMarioInstance,
  binInstance,
  manualGatewayInstance,
  riskEngineGatewayInstance,
  jxSanctionsGatewayInstance,
  bowserInstance,
  warioInstance,
  billingInstance,
  ruleEngineInstance,
  setAxiosAuthorization,
  setAxiosAcceptLanguage
}
