import { loadView, getMappedPermission } from './utils'
import MODULES from '../constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const BIN_PERMISSION = 'BIN'

export const binRoot = {
  path: '/bin',
  component: loadView('ViewBin', MODULES.BIN)
}

export const binList = {
  path: '',
  name: 'bin',
  component: loadView('ViewBinList', MODULES.BIN),
  meta: {
    navigationDrawer: true,
    module: MODULES.BIN,
    permission: getMappedPermission(PERMISSION_TYPES.READ, BIN_PERMISSION),
    breadcrumb: () => {
      return {
        text: i18n.t('bin.title.list')
      }
    }
  }
}

export const binCreate = {
  path: 'create',
  name: 'bin-create',
  component: loadView('ViewBinCreate', MODULES.BIN),
  meta: {
    navigationDrawer: false,
    module: MODULES.BIN,
    permission: getMappedPermission(PERMISSION_TYPES.EDIT, BIN_PERMISSION)
  }
}

export const binEdit = {
  path: ':binId/edit',
  name: 'bin-edit',
  component: loadView('ViewBinEdit', MODULES.BIN),
  meta: {
    navigationDrawer: false,
    module: MODULES.BIN,
    permission: getMappedPermission(PERMISSION_TYPES.EDIT, BIN_PERMISSION),
    breadcrumb: () => {
      return {
        text: i18n.t('bin.title.edit'),
        parent: binDetails.name
      }
    }
  }
}

export const binDetails = {
  path: ':binId',
  name: 'bin-details',
  component: loadView('ViewBinDetails', MODULES.BIN),
  meta: {
    navigationDrawer: true,
    module: MODULES.BIN,
    permission: getMappedPermission(PERMISSION_TYPES.READ, BIN_PERMISSION),
    breadcrumb: () => {
      return {
        text: i18n.t('bin.title.details'),
        parent: binList.name
      }
    }
  }
}

export default [
  {
    ...binRoot,
    children: [binList, binCreate, binDetails, binEdit]
  }
]
