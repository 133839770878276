import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import { loadLanguage } from '../i18n'
import operatingAccountsRoutes from './operating-accounts'
import manualWithdrawalsRoutes from './manual-withdrawals'
import requestsManagementRoutes from './requests-management'
import decisionsRoutes from './decisions'
import listsManagementRoutes from './lists-management'
import purchasesRoutes from './purchases'
import jxSanctionsRoutes from './jx-sanctions'
import businessesRoutes from './businesses'
import settlementsByBankRoutes from './settlement-by-bank'
import binRoutes from './bin'
import { loadView } from './utils'
import { localStorageKeys } from '@/constants/index'

Vue.use(Router)

const baseRoutes = [
  {
    path: '/',
    name: 'homepage',
    component: loadView('ViewHomepage')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...baseRoutes,
    ...operatingAccountsRoutes,
    ...manualWithdrawalsRoutes,
    ...requestsManagementRoutes,
    ...decisionsRoutes,
    ...listsManagementRoutes,
    ...purchasesRoutes,
    ...jxSanctionsRoutes,
    ...businessesRoutes,
    ...settlementsByBankRoutes,
    ...binRoutes
  ]
})

function isHomepageRoute(to) {
  return to.name === 'homepage'
}

async function nextHomePage(to, next) {
  if (isHomepageRoute(to)) {
    await loadStoreAndLanguages(to)
    next()
  } else {
    next({ name: 'homepage' })
  }
}

async function nextAllowed(to, next) {
  if (isHomepageRoute(to)) {
    const menu = await store.state.app.menu
    const [firstAvailableModule] = menu.filter((el) => !el.isGroup)

    if (firstAvailableModule.modules) {
      next({ name: firstAvailableModule.modules[0].to.name })
    } else {
      next({ name: firstAvailableModule.to.name })
    }
  } else {
    const storedRoute = localStorage.getItem(localStorageKeys.route)
    if (storedRoute) {
      localStorage.removeItem(localStorageKeys.route)
      await loadStoreAndLanguages(JSON.parse(storedRoute))
      next(JSON.parse(storedRoute))
    } else {
      await loadStoreAndLanguages(to)
      next()
    }
  }
}

function hasMandatoryInformation() {
  const authToken = localStorage.getItem(localStorageKeys.authToken)
  const refreshToken = localStorage.getItem(localStorageKeys.refreshToken)
  const email = localStorage.getItem(localStorageKeys.email)

  return authToken && refreshToken && email
}

router.beforeEach(async (to, from, next) => {
  if (from.name !== to.name) {
    store.dispatch('app/loadingPageStart')
  }

  if (hasMandatoryInformation()) {
    if (from.name === null) {
      try {
        await store.dispatch('app/getPermissions')
        nextAllowed(to, next)
      } catch {
        await store.dispatch('app/logout')
        nextHomePage(to, next)
      }
    } else {
      nextAllowed(to, next)
    }
  } else {
    try {
      const authCode = to.query.code

      await store.dispatch('app/login', { authCode })

      if (authCode) {
        const newQueryParams = { ...to.query }
        delete newQueryParams.code
        next({ path: to.path, query: newQueryParams })
      } else {
        nextAllowed(to, next)
      }
    } catch (error) {
      nextHomePage(to, next)
    }
  }
})

async function loadStoreAndLanguages(to) {
  const module = to.meta.module
  await loadLanguage({ module })

  if (module) {
    if (!(module in store._modules.root._children)) {
      const response = await import(`@/modules/${module}/_store`)
      await store.registerModule(module, response.default)
    }
  }
}

router.afterEach(() => {
  store.dispatch('app/loadingPageEnd')
})

export default router
