import { loadView, getMappedPermission } from './utils'
import MODULES from '../constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const settlementsByBankRoot = {
  path: '/settlement-by-bank',
  component: loadView('ViewSettlementsByBank', MODULES.SETTLEMENTS_BY_BANK)
}

export const settlementsByBankList = {
  path: '',
  name: 'settlement-by-bank',
  component: loadView('ViewSettlementsByBankList', MODULES.SETTLEMENTS_BY_BANK),
  meta: {
    navigationDrawer: true,
    module: MODULES.SETTLEMENTS_BY_BANK,
    permission: getMappedPermission(
      PERMISSION_TYPES.RETRY,
      MODULES.SETTLEMENTS_BY_BANK
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('settlement-by-bank.title.list')
      }
    }
  }
}

export default [
  {
    ...settlementsByBankRoot,
    children: [settlementsByBankList]
  }
]
