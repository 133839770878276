import { loadView, getMappedPermission } from './utils'
import MODULES from '@/constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const decisionsRootRoute = {
  path: '/decisions',
  component: loadView('ViewDecisions', MODULES.DECISIONS)
}

export const decisionsListRoute = {
  path: '',
  name: 'decisions',
  component: loadView('ViewDecisionsList', MODULES.DECISIONS),
  meta: {
    navigationDrawer: true,
    module: MODULES.DECISIONS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.DECISIONS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('decisions.title.list')
      }
    }
  }
}
export const decisionCreateRoute = {
  path: 'new',
  name: 'decision-add',
  component: loadView('ViewDecisionCreate', MODULES.DECISIONS),
  meta: {
    module: MODULES.DECISIONS,
    permission: getMappedPermission(PERMISSION_TYPES.CREATE, MODULES.DECISIONS)
  }
}

export const decisionDetailsRoute = {
  path: ':id',
  name: 'decision-details',
  component: loadView('ViewDecisionDetails', MODULES.DECISIONS),
  meta: {
    navigationDrawer: true,
    module: MODULES.DECISIONS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.DECISIONS
    ),
    notificationFixed: true,
    breadcrumb: () => {
      return {
        text: i18n.t('decisions.title.details'),
        parent: decisionsListRoute.name
      }
    }
  }
}

export const decisionEditRoute = {
  path: ':id/edit',
  name: 'decision-edit',
  component: loadView('ViewDecisionEdit', MODULES.DECISIONS),
  meta: {
    module: MODULES.DECISIONS,
    permission: getMappedPermission(PERMISSION_TYPES.CREATE, MODULES.DECISIONS)
  }
}

export default [
  {
    ...decisionsRootRoute,
    children: [
      decisionsListRoute,
      decisionCreateRoute,
      decisionDetailsRoute,
      decisionEditRoute
    ]
  }
]
