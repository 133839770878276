import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { i18n } from '../../i18n'
const state = {
  loadingPage: false,
  email: localStorage.getItem('email'),
  menu: [],
  permissions: [],
  snackbar: {
    type: undefined,
    icon: undefined,
    messages: [],
    textColor: undefined,
    backgroundColor: undefined
  },
  genericError: {
    title: i18n.t('error.title'),
    description: i18n.t('error.description')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
