import { loadView, getMappedPermission } from './utils'
import MODULES from '@/constants/modules'
import PERMISSION_TYPES from '@/constants/permissions'

export const requestManagementRoot = {
  path: '/requests',
  component: loadView('ViewRequestsManagement', MODULES.REQUESTS_MANAGEMENT)
}

export const requestDetails = {
  path: '/requests/:id',
  name: 'requests-details',
  component: loadView('ViewRequestDetails', MODULES.REQUESTS_MANAGEMENT),
  meta: {
    navigationDrawer: true,
    module: MODULES.REQUESTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.REQUESTS_MANAGEMENT
    )
  }
}

export const requestManagementList = {
  path: '',
  name: 'requests',
  component: loadView('ViewRequestManagementList', MODULES.REQUESTS_MANAGEMENT),
  meta: {
    navigationDrawer: true,
    module: MODULES.REQUESTS_MANAGEMENT,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.REQUESTS_MANAGEMENT
    )
  }
}

export default [
  {
    ...requestManagementRoot,
    children: [requestManagementList, requestDetails]
  }
]
