export default {
  READ: 'read',
  READ_LIST: 'read_list',
  CREATE: 'create',
  EDIT: 'edit',
  COMPLETE: 'complete',
  PROCESS: 'process',
  DOWNLOAD_PENDING: 'download_pending',
  EXPORT: 'export',
  READ_LIST_BULK: 'read_list_bulk',
  CREATE_BULK: 'create_bulk',
  READ_BULK: 'read_bulk',
  UPDATE_BULK: 'update_bulk',
  REOPEN: 'reopen',
  READ_LIST_DOCUMENTS: 'read_list_documents',
  DOWNLOAD_DOCUMENTS: 'download_documents',
  RETRY: 'retry'
}
