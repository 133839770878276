import { loadView, getMappedPermission } from './utils'
import MODULES from '../constants/modules'
import PERMISSION_TYPES from '../constants/permissions'
import { i18n } from '../i18n'

export const manualWithdrawalsRoot = {
  path: '/manual-withdrawals',
  component: loadView('ViewManualWithdrawals', MODULES.MANUAL_WITHDRAWALS)
}

export const manualWithdrawalsList = {
  path: '',
  name: 'manual-withdrawals-list',
  component: loadView('ViewManualWithdrawalsList', MODULES.MANUAL_WITHDRAWALS),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('manual-withdrawals.title.list')
      }
    }
  }
}

export const manualWithdrawalDetails = {
  path: ':manualWithdrawalId',
  name: 'manual-withdrawal-details',
  component: loadView(
    'ViewManualWithdrawalDetails',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('manual-withdrawals.title.details'),
        parent: manualWithdrawalsList.name
      }
    }
  }
}

export const manualWithdrawalComplete = {
  path: ':manualWithdrawalId/complete',
  name: 'manual-withdrawal-complete',
  component: loadView(
    'ViewManualWithdrawalsComplete',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.COMPLETE,
      MODULES.MANUAL_WITHDRAWALS
    )
  }
}

export const manualWithdrawalFail = {
  path: ':manualWithdrawalId/fail',
  name: 'manual-withdrawal-fail',
  component: loadView('ViewManualWithdrawalsFail', MODULES.MANUAL_WITHDRAWALS),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.COMPLETE,
      MODULES.MANUAL_WITHDRAWALS
    )
  }
}

export const manualWithdrawalsBulkPrecessingRoot = {
  path: '/manual-withdrawals-bulk-processing',
  component: loadView(
    'ViewManualWithdrawalsBulkProcessing',
    MODULES.MANUAL_WITHDRAWALS
  )
}

export const manualWithdrawalsBulkProcessing = {
  path: '',
  name: 'manual-withdrawals-bulk-processing',
  component: loadView(
    'ViewManualWithdrawalsBulkProcessingSummary',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('manual-withdrawals.title.bulk_processing')
      }
    }
  }
}

export const manualWithdrawalsBulkProcessingList = {
  path: 'pending',
  name: 'manual-withdrawals-bulk-processing-list',
  component: loadView(
    'ViewManualWithdrawalsBulkProcessingList',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('manual-withdrawals.title.pending_withdrawals_list'),
        parent: manualWithdrawalsBulkProcessing.name
      }
    }
  }
}

export const manualWithdrawalsBulkProcessingDetails = {
  path: 'pending/:manualWithdrawalId',
  name: 'manual-withdrawals-bulk-processing-details',
  component: loadView(
    'ViewManualWithdrawalsBulkProcessingDetails',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('manual-withdrawals.title.details'),
        parent: manualWithdrawalsBulkProcessingList.name
      }
    }
  }
}

export const manualWithdrawalsBulkFilesList = {
  path: 'bulk-files',
  name: 'manual-withdrawals-bulk-files-list',
  component: loadView(
    'ViewManualWithdrawalsBulkFilesList',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST_BULK,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('manual-withdrawals.title.bulk_files_list'),
        parent: manualWithdrawalsBulkProcessing.name
      }
    }
  }
}

export const manualWithdrawalsBulkFileDetails = {
  path: 'bulk-files/:bulkFileId',
  name: 'manual-withdrawals-bulk-file-details',
  component: loadView(
    'ViewManualWithdrawalsBulkFileDetails',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_BULK,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: ({ name }) => {
      return {
        text: name,
        parent: manualWithdrawalsBulkFilesList.name
      }
    }
  }
}

export const manualWithdrawalsBulkFileWithdrawalDetails = {
  path: 'bulk-files/:bulkFileId/:manualWithdrawalId',
  name: 'manual-withdrawals-bulk-file-withdrawal-details',
  component: loadView(
    'ViewManualWithdrawalsBulkFileWithdrawalDetails',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    navigationDrawer: true,
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ,
      MODULES.MANUAL_WITHDRAWALS
    ),
    breadcrumb: () => {
      return {
        text: i18n.t('manual-withdrawals.title.details'),
        parent: manualWithdrawalsBulkFileDetails.name
      }
    }
  }
}

export const manualWithdrawalsBulkFilesCreate = {
  path: 'new',
  name: 'bulk-files-create',
  component: loadView(
    'ViewManualWithdrawalsBulkFilesCreate',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.CREATE_BULK,
      MODULES.MANUAL_WITHDRAWALS
    )
  }
}

export const manualWithdrawalsBulkFilesEdit = {
  path: 'bulk-files/:bulkFileId/edit',
  name: 'bulk-files-edit',
  component: loadView(
    'ViewManualWithdrawalsBulkFilesEdit',
    MODULES.MANUAL_WITHDRAWALS
  ),
  meta: {
    module: MODULES.MANUAL_WITHDRAWALS,
    permission: getMappedPermission(
      PERMISSION_TYPES.UPDATE_BULK,
      MODULES.MANUAL_WITHDRAWALS
    )
  }
}

export default [
  {
    ...manualWithdrawalsRoot,
    children: [
      manualWithdrawalsList,
      manualWithdrawalDetails,
      manualWithdrawalComplete,
      manualWithdrawalFail
    ]
  },
  {
    ...manualWithdrawalsBulkPrecessingRoot,
    children: [
      manualWithdrawalsBulkProcessing,
      manualWithdrawalsBulkProcessingList,
      manualWithdrawalsBulkFilesList,
      manualWithdrawalsBulkFilesCreate,
      manualWithdrawalsBulkFilesEdit,
      manualWithdrawalsBulkProcessingDetails,
      manualWithdrawalsBulkFileDetails,
      manualWithdrawalsBulkFileWithdrawalDetails
    ]
  }
]
