import axios from 'axios'
import { localStorageKeys } from '@/constants/index'
import { endpoints } from './authentication'
import { headers } from './axios-configurations'
import store from '../store'
import router from '../router'
import { isUnauthorized, isForbidden } from '@JumiaPayAIG/js-utils/errors'

async function errorHandler(error) {
  if (axios.isCancel(error)) {
    return Promise.reject(error)
  }

  const { config: originalRequest, config: { url: endpoint } = {} } = error

  if (endpoint.includes(endpoints.login)) {
    return Promise.reject(error)
  }

  if (endpoint.includes(endpoints.refreshToken)) {
    await store.dispatch('app/logout')
    await router.push({ name: 'homepage' })
    return Promise.reject(error)
  }

  if (isUnauthorized(error)) {
    if (
      !originalRequest._retry &&
      !endpoint.includes(endpoints.login) &&
      !endpoint.includes(endpoints.refreshToken)
    ) {
      originalRequest._retry = true

      await store.dispatch('app/refreshToken')

      originalRequest.headers[
        headers.authorization
      ] = `Bearer ${localStorage.getItem(localStorageKeys.authToken)}`

      return axios(originalRequest)
    }

    await store.dispatch('app/logout')
    await router.push({ name: 'homepage' })
  }

  if (isForbidden(error)) {
    try {
      await store.dispatch('app/getPermissions')
    } catch (error) {
      await store.dispatch('app/logout')
      await router.push({ name: 'homepage' })
    }
  }

  return Promise.reject(error)
}

export { errorHandler }
