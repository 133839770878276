import { i18n } from '@/i18n'
import { mdiCheck, mdiAlertCircleOutline } from '@mdi/js'

function LOADING_PAGE_START(state) {
  state.loadingPage = true
}

function LOADING_PAGE_END(state) {
  state.loadingPage = false
}

function EMAIL_UPDATED(state, email) {
  state.email = email
}

function PERMISSIONS_UPDATED(state, permissions) {
  state.permissions = permissions
}

function SNACKBAR_UPDATED(state, { type, action, module, message }) {
  if (type) {
    state.snackbar.type = type
    state.snackbar.icon = type === 'success' ? mdiCheck : mdiAlertCircleOutline
    state.snackbar.textColor = type === 'success' ? '#417505' : '#b81a0a'
    state.snackbar.backgroundColor = type === 'success' ? '#d9e3cd' : '#f1d1ce'

    if (message) {
      state.snackbar.messages = Array.isArray(message) ? message : [message]
    } else if (action && module) {
      state.snackbar.messages = [
        i18n.t(`success_message.${action}`, {
          entity: i18n.t(`${module}.entity`)
        })
      ]
    }
  }
}

function SNACKBAR_CLEANED(state) {
  state.snackbar.type = undefined
  state.snackbar.icon = undefined
  state.snackbar.textColor = undefined
  state.snackbar.backgroundColor = undefined
  state.snackbar.messages = undefined
}

function MENU_ITEMS_UPDATED(state, menuItems) {
  state.menu = menuItems
}

export default {
  LOADING_PAGE_START,
  LOADING_PAGE_END,
  EMAIL_UPDATED,
  PERMISSIONS_UPDATED,
  SNACKBAR_UPDATED,
  SNACKBAR_CLEANED,
  MENU_ITEMS_UPDATED
}
