import { loadView, getMappedPermission } from './utils'
import MODULES from '../constants/modules'
import PERMISSION_TYPES from '../constants/permissions'

export const operatingAccountsRoot = {
  path: '/operating-accounts',
  component: loadView('ViewOperatingAccounts', MODULES.OPERATING_ACCOUNTS)
}

export const operatingAccountsList = {
  path: '',
  name: 'operating-accounts',
  component: loadView('ViewOperatingAccountsList', MODULES.OPERATING_ACCOUNTS),
  meta: {
    navigationDrawer: true,
    module: MODULES.OPERATING_ACCOUNTS,
    permission: getMappedPermission(
      PERMISSION_TYPES.READ_LIST,
      MODULES.OPERATING_ACCOUNTS
    )
  }
}

export const operatingAccountCreate = {
  path: 'new',
  name: 'operating-accounts-add',
  component: loadView('ViewOperatingAccountsAdd', MODULES.OPERATING_ACCOUNTS),
  meta: {
    module: MODULES.OPERATING_ACCOUNTS,
    permission: getMappedPermission(
      PERMISSION_TYPES.CREATE,
      MODULES.OPERATING_ACCOUNTS
    )
  }
}

export default [
  {
    ...operatingAccountsRoot,
    children: [operatingAccountsList, operatingAccountCreate]
  }
]
